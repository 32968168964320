import styles from "./index.module.scss";
interface ITabs {
  listHandler: () => void;
  mapHandler: () => void;
  activeTab: 'list' | 'map';
}
const Tabs = (props: ITabs) => {
  const {
    activeTab,
    listHandler,
    mapHandler
  } = props;
  return <div className={styles.tabs} data-sentry-component="Tabs" data-sentry-source-file="index.tsx">
      <button onClick={listHandler} className={`${styles.tabs_button} ${activeTab === 'list' ? styles.active : ''}`}>
        Список
      </button>
      <button onClick={mapHandler} className={`${styles.tabs_button} ${activeTab === 'map' ? styles.active : ''}`}>
        Карта
      </button>
    </div>;
};
export default Tabs;