import styles from "./confirm-popup.module.scss";
import Modal from "../../modal/modal";
import { FC } from "react";
import { IPopup } from "@/services/types/types";
import ButtonComponent from "@/components/button/button";
export const ConfirmPopup: FC<IPopup> = ({
  isOpen,
  onClose,
  title,
  text,
  action
}) => {
  return <Modal isOpen={isOpen} title={title} onClose={onClose} data-sentry-element="Modal" data-sentry-component="ConfirmPopup" data-sentry-source-file="confirm-popup.tsx">
      <div className={styles.wrap}>
        <p className={styles.subtitle}>{text}</p>
        <div className={styles.buttonWrap}>
          <ButtonComponent onClick={action} className={styles.confirm} data-sentry-element="ButtonComponent" data-sentry-source-file="confirm-popup.tsx">
            <>Подтвердить</>
          </ButtonComponent>
          <ButtonComponent onClick={onClose} className={styles.cancel} data-sentry-element="ButtonComponent" data-sentry-source-file="confirm-popup.tsx">
            <>Отменить</>
          </ButtonComponent>
        </div>
      </div>
    </Modal>;
};