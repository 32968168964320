import classNames from "classnames";
import styles from "./loading.module.scss";
import { FC } from "react";
export const Preloader: FC<{
  className?: string;
}> = ({
  className
}) => {
  const preloaderClassNames = classNames({
    [className!]: className,
    [styles.preloaderWrap]: true
  });
  return <div className={preloaderClassNames} data-sentry-component="Preloader" data-sentry-source-file="preloader.tsx">
      <div className={styles.loader}></div>
    </div>;
};
export const PreloaderMini: FC<{
  className?: string;
}> = ({
  className
}) => {
  const preloaderClassNames = classNames({
    [className!]: className,
    [styles.preloaderWrap]: true
  });
  const loaderClassNames = classNames({
    [styles.loader]: true,
    [styles.mini]: true
  });
  return <div className={preloaderClassNames} data-sentry-component="PreloaderMini" data-sentry-source-file="preloader.tsx">
      <div className={loaderClassNames}></div>
    </div>;
};