import { FC, createRef, useEffect, useState } from "react";
import styles from "./enter.module.scss";
import TelInput from "@/components/tel-input/tel-input";
import ButtonComponent from "@/components/button/button";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { clearAuth, getAuthUUID, getAuthVariant, loginEmail, loginTel, setUUID, setVariant } from "@/services/redux/features/userSlice";
import { AppQrCodeIcon, MailRuColorIcon, OkColorIcon, VkColorIcon, YaColorIcon } from "@/components/icon";
import Link from "next/link";
import { phoneValidate } from "@/utils/functions/phoneValidate";
import EmailInput from "@/components/email-input/email-input";
import isValidEmail from "@/utils/functions/is-valid-email";
import { callMessage } from "@/utils/functions/callMessage";
import { EnterQr } from "./enter-qr/enter-qr";
interface IEnter {
  action?: () => void;
  onClose: () => void;
}
export const Enter: FC<IEnter> = props => {
  const {
    action,
    onClose
  } = props;
  const [qrVariant, setQrVariant] = useState(false);
  const variant = useAppSelector(getAuthVariant);
  const origin = document.location.origin;
  const authUUID = useAppSelector(getAuthUUID);
  const ref = createRef() as any;
  const dispatch = useAppDispatch();
  const changeVariant = (variant: string) => {
    dispatch(setVariant(variant));
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    if (!ref.current.value.length) {
      return callMessage("Ошибка!", `Пожалуйста, заполните поле с ${variant === "email" ? "email" : "номером телефона"}.`, false);
    }
    if (variant === "tel") {
      if (phoneValidate(ref.current.value)) {
        return dispatch(loginTel({
          value: ref.current.value.replace(/[^\d]/g, "").slice(1)
        }));
      } else {
        return callMessage("Ошибка!", "Номер телефона заполнен некорректно. Пожалуйста, проверьте ваш номер телефона.", false);
      }
    }
    if (variant === "email") {
      if (isValidEmail(ref.current.value)) {
        return dispatch(loginEmail({
          email: ref.current.value
        }));
      } else {
        return callMessage("Ошибка!", "Email заполнен некорректно. Пожалуйста, проверьте ваш email", false);
      }
    }
  };
  useEffect(() => {
    dispatch(clearAuth());
    dispatch(setUUID());
  }, []);
  return qrVariant ? <EnterQr onClose={onClose} data-sentry-element="EnterQr" data-sentry-component="Enter" data-sentry-source-file="enter.tsx" /> : <>
      <p className={styles.infoText}>
        {variant === "tel" && `Мы отправим код или позвоним. Отвечать на звонок не нужно.`}
        {variant === "email" && `На указанный E-mail будет отправлено письмо с кодом.`}
      </p>
      <form className={styles.form} onSubmit={handleClick}>
        {variant === "tel" && <TelInput
      // @ts-ignore: Unreachable code error
      id={"auth-input"} label="Введите телефон" className={styles.inputWrap} ref={ref} placeholder=" " data-qr={"phone"} />}
        {variant === "email" && <EmailInput
      // @ts-ignore: Unreachable code error
      id={"auth-email"} label="Введите email" className={styles.inputWrap} ref={ref} placeholder=" " />}
        <ButtonComponent className={styles.button} type={"submit"} data-sentry-element="ButtonComponent" data-sentry-source-file="enter.tsx">
          <>Войти</>
        </ButtonComponent>
        {action && <Link prefetch={false} href={"/order"} className={styles.buttonAction}>
            <>Пропустить</>
          </Link>}
      </form>
      <p className={styles.or}>или</p>
      <div className={styles.anotherWrap}>
        <p className={styles.anotherText}>Войти через:</p>
        <div className={styles.anotherList}>
          <a className={styles.link} href={`https://id.vk.com/auth?app_id=51779355&response_type=silent_token&v=1.60.7&redirect_uri=${origin}/auth/vk/&uuid=${authUUID}`}>
            <VkColorIcon data-sentry-element="VkColorIcon" data-sentry-source-file="enter.tsx" />
          </a>
          <a className={styles.link} href={`https://oauth.yandex.ru/authorize?response_type=code&client_id=a001acf267ca4f66a9ec7e2f0839aad7&state=${authUUID}&redirect_uri=${origin}/auth/ya/`}>
            <YaColorIcon data-sentry-element="YaColorIcon" data-sentry-source-file="enter.tsx" />
          </a>
          <a className={styles.link} href={`https://oauth.mail.ru/xlogin?client_id=ad3cb490d6b94908a2836da9cf21afbc&response_type=code&scope=userinfo&redirect_uri=${origin}/auth/mailru/&state=${authUUID}`}>
            <MailRuColorIcon data-sentry-element="MailRuColorIcon" data-sentry-source-file="enter.tsx" />
          </a>
          <a className={styles.link} style={{
          display: "none"
        }} href={`https://connect.ok.ru/oauth/authorize?client_id=512002055263&response_type=code&redirect_uri=${origin}/auth/ok/&state=${authUUID}`}>
            <OkColorIcon data-sentry-element="OkColorIcon" data-sentry-source-file="enter.tsx" />
          </a>
          <button className={styles.buttonLink} onClick={() => setQrVariant(true)}>
            <AppQrCodeIcon data-sentry-element="AppQrCodeIcon" data-sentry-source-file="enter.tsx" />
          </button>
        </div>
        <button className={styles.anotherButton} onClick={() => changeVariant(variant === "email" ? "tel" : "email")}>
          {variant === "tel" ? "Войти по email" : "Войти по номеру телефона"}
        </button>
      </div>
    </>;
};