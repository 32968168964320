import styles from "./skeleton-loader.module.scss";
import { FC } from "react";
interface ISkeletonLoader {
  containerTag: string;
  baseColor?: string;
  highlightColor?: string;
  height?: string | number;
  width?: string | number;
  borderRadius?: string | number;
  duration?: number;
  enableAnimation?: boolean;
  className?: string;
  mb?: number;
  mt?: number;
  ml?: number | 'auto';
  mr?: number | 'auto';
}
export const SkeletonLoader: FC<ISkeletonLoader> = props => {
  const {
    className,
    baseColor = "#f7f8f9",
    highlightColor = "#1d1d1d",
    height = '100%',
    width = '100%',
    borderRadius = 4,
    duration = 1,
    enableAnimation = true,
    containerTag = "div",
    mb = 0,
    mt = 0,
    ml = 0,
    mr = 0
  } = props;
  return <>
      {{
      'div': <div className={`
            ${className}
            ${styles.skeleton}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: ml === 'auto' ? 'auto' : `${ml}px`,
        marginRight: mr === 'auto' ? 'auto' : `${mr}px`
      }}>
              <div className={`
                  ${styles.skeleton_highlight} 
                  ${enableAnimation && styles.animated}
                `} />
            </div>,
      'li': <li className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9"
      }}>
              <div className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </li>,
      'p': <p className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <div className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </p>,
      'a': <p className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <div className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </p>,
      'span': <span className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <div className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </span>,
      'h1': <h1 className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <span className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </h1>,
      'h2': <h2 className={`
              ${styles.skeleton}
              ${className}
            `} style={{
        width: typeof width === "number" ? `${width}px` : `${width}`,
        height: typeof height === "number" ? `${height}px` : `${height}`,
        borderRadius: `${borderRadius}px`,
        backgroundColor: baseColor ? `${baseColor}` : "#f7f8f9",
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`
      }}>
              <span className={`
                ${styles.skeleton_highlight} 
                ${enableAnimation && styles.animated}
              `} />
            </h2>
    }[containerTag]}
    </>;
};