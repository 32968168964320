import isValidEmail from "./is-valid-email";
import { phoneValidate } from "./phoneValidate";
import { callMessage } from "./callMessage";
interface IPartnerValidateForm {
  name: string;
  tel: string;
  email: string;
}
export const analogValidate = (props: IPartnerValidateForm) => {
  const {
    name,
    tel,
    email
  } = props;
  if (!isValidEmail(email)) {
    callMessage("Ошибка!", "Email введен неверно.", false);
    return false;
  } else if (name) {
    const cleanName = name.replace(/\s+/g, " ").trim();
    const words: Array<string> = cleanName.split(" ");
    if (!(words.length >= 1) || !words.every(word => word.length >= 1)) {
      callMessage("Ошибка!", "Проверьте поле ФИО.", false);
      return false;
    }
  } else if (!tel) {
    callMessage("Ошибка!", "Проверьте поле с номером телефона.", false);
    return false;
  }
  if (phoneValidate(tel) === false) {
    return false;
  }
  return callMessage("Успешно!", "Ваша заявка принята, менеджер свяжется с Вами в ближайшее время.", true);
};