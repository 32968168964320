import { IShop } from "@/services/types/types";
import styles from "./available-popup-store.module.scss";
import { FC } from "react";
import { TagIcon } from "@/components/icon";
import Link from "next/link";
interface IAvailablePopupStore {
  store: IShop | {
    value: string;
    title: string;
    schedule: string;
    quantity: string;
  };
  warehouse?: boolean;
}
;
export const AvailablePopupStore: FC<IAvailablePopupStore> = props => {
  const {
    store,
    warehouse = false
  } = props;
  return <li className={styles.store_wrap} data-sentry-component="AvailablePopupStore" data-sentry-source-file="available-popup-store.tsx">
      <TagIcon className={styles.icon} data-sentry-element="TagIcon" data-sentry-source-file="available-popup-store.tsx" />
      <div className={styles.info}>
        {warehouse ? <p className={styles.name}>
            {store.title}
          </p> : <Link prefetch={false} className={styles.name} href={`/shops/${store.value}`}>
            {store.title}
          </Link>}
        {!warehouse && <p className={styles.schedule}>{store.schedule}</p>}
        {!warehouse && <p className={styles.quantity}>в наличии {store.quantity} шт.</p>}
      </div>
    </li>;
};