import { authQrRequest } from "@/utils/api/auth";
import styles from "./enter-qr.module.scss";
import { FC, useEffect, useState, useRef } from "react";
import { QRCodeSVG } from "qrcode.react";
import { getCookie } from "@/utils/functions/cookie";
import { callMessage } from "@/utils/functions/callMessage";
import { useAppDispatch } from "@/services/redux/hooks";
import { sendAuthQrCode } from "@/services/redux/features/userSlice";
import { EnterQrSocial } from "./enter-qr-social/enter-qr-social";
import { RestoreIcon } from "@/components/icon";
import { SkeletonLoader } from "@/components/skeleton-loader/skeleton-loader";
interface IEnterQr {
  onClose: () => void;
}
export const EnterQr: FC<IEnterQr> = ({
  onClose
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [code, setCode] = useState("");
  const [expiryTime, setExpiryTime] = useState<number | null>(null);
  const [timeLeft, setTimeLeft] = useState<number | null>(59);
  const [shouldConnect, setShouldConnect] = useState(false);
  const [isQrVisible, setIsQrVisible] = useState(false);
  const sseInstance = useRef<EventSource | null>(null);
  const fetchQrCode = () => {
    setIsLoading(true);
    authQrRequest().then(res => {
      if (res.data?.code && res.data?.time) {
        setCode(res.data.code);
        setExpiryTime(res.data.time);
        setShouldConnect(true);
        setIsQrVisible(true);
        setIsLoading(false);
      }
    }).catch(err => {
      setIsLoading(false);
    });
  };
  useEffect(() => {
    fetchQrCode();
  }, []);
  useEffect(() => {
    if (expiryTime) {
      const intervalId = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeLeft = expiryTime - currentTime;
        setTimeLeft(timeLeft);
        if (timeLeft <= 0) {
          setExpiryTime(null);
          setShouldConnect(false);
          setTimeLeft(59);
          clearInterval(intervalId);
          setIsQrVisible(false);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [expiryTime]);
  useEffect(() => {
    if (shouldConnect && !sseInstance.current) {
      const sseUrl = `https://hot.ohotaktiv.ru:8443/events/${getCookie("deviceId")}`;
      sseInstance.current = new EventSource(sseUrl);
      sseInstance.current.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        handleMessage(data);
      };
      sseInstance.current.onerror = () => {
        handleError();
      };
    }
    return () => {
      if (sseInstance.current) {
        sseInstance.current.close();
        sseInstance.current = null;
      }
    };
  }, [shouldConnect]);
  const handleMessage = (data: {
    auth?: boolean;
  }) => {
    setIsLoading(true);
    if (sseInstance.current) {
      sseInstance.current.close();
      sseInstance.current = null;
    }
    if (data.auth && data.auth === true) {
      dispatch(sendAuthQrCode({
        code: code.split("?code=")[1]
      })).then(() => {
        setIsLoading(false);
        onClose();
      });
    }
  };
  const handleError = () => {
    onClose();
    callMessage("Ошибка!", "Произошла ошибка, пожалуйста повторите попытку позднее", false);
  };
  return <div className={styles.wrap} data-sentry-component="EnterQr" data-sentry-source-file="enter-qr.tsx">
      <p className={styles.title}>
        Отсканируйте QR-код в мобильном приложении ОхотАктив чтобы войти в
        аккаунт, либо камерой мобильного телефона*
      </p>
      <div className={styles.qrWrapper}>
        {isLoading ? <div className={styles.qrContainer}>
            <SkeletonLoader containerTag="div" width={128} height={128} mb={12} />
            <SkeletonLoader containerTag="div" width={260} height={24} />
          </div> : <div className={styles.qrContainer}>
            <div className={isQrVisible ? styles.qrVisible : styles.qrBlurred}>
              <QRCodeSVG value={code} />
              <p className={styles.timer}>
                Время до истечения: {timeLeft} секунд
              </p>
            </div>
            {!isQrVisible && <button className={styles.refreshButton} onClick={fetchQrCode}>
                <RestoreIcon fill="#fff" size={40} />
              </button>}
          </div>}
      </div>
      <p className={styles.title}>Скачайте мобильное приложение ОхотАктив</p>
      <EnterQrSocial data-sentry-element="EnterQrSocial" data-sentry-source-file="enter-qr.tsx" />
      <p className={styles.text}>
        * - Этот вид авторизации находится в бета-тестировании (β), убедитесь,
        что вы используете последнюю версию мобильного приложения
      </p>
    </div>;
};