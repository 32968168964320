"use client";

import { createPortal } from "react-dom";
import ModalOverlay from "./modal-overlay/modal-overlay";
import styles from "./modal.module.scss";
import { useEffect, FC, useState } from "react";
import ModalHeader from "./modal-header/modal-header";
import { IModalComponent } from "@/services/types/types";
import { AnimatePresence, motion } from "framer-motion";
import ModalClose from "./modal-close/modal-close";
import classNames from "classnames";
const Modal: FC<IModalComponent> = props => {
  const {
    isOpen,
    children,
    title,
    onClose,
    className,
    closeColor,
    isHeader = true,
    containerClassname,
    showClose = true,
    overlayClass = ''
  } = props;
  const wrapClassnames = classNames({
    [styles.modal]: true,
    [className!]: true
  });
  const [modalRoot, setModalRoot] = useState<any>();
  const dropIn = {
    hidden: {
      top: "48%",
      opacity: 0
    },
    visible: {
      top: "50%",
      opacity: 1
    },
    exit: {
      top: "48%",
      opacity: 0
    }
  };
  useEffect(() => {
    setModalRoot(document.getElementById("modals"));
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        if (onClose) {
          onClose();
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
    // eslint-disable-next-line
  }, []);
  if (!modalRoot) {
    return null;
  }
  return createPortal(<>
      <AnimatePresence>
        {isOpen && <motion.div>
            <ModalOverlay onClose={onClose} overlayClass={overlayClass} />
            <motion.div key="modal" className={wrapClassnames} variants={dropIn} initial="hidden" animate="visible" exit="exit" onClick={e => e.stopPropagation()}>
              <div className={`${styles.modal_container} ${containerClassname}`}>
                {!!title && <ModalHeader title={title} />}
                {children}
              </div>
              {showClose ? <ModalClose onClose={onClose} closeColor={closeColor} /> : null}
            </motion.div>
          </motion.div>}
      </AnimatePresence>
    </>, modalRoot);
};
export default Modal;