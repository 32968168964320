import classNames from "classnames";
import styles from "./button.module.scss";
import { IButtonComponent } from "@/services/types/types";
import React, { FC } from "react";
const ButtonComponent: FC<IButtonComponent> = React.forwardRef(({
  id,
  children,
  className,
  onClick,
  type = "button",
  disabled,
  ...props
}, ref: React.Ref<HTMLButtonElement>) => {
  const buttonClassNames = classNames({
    [styles.button]: true,
    [className!]: className
  });
  return <button {...props} type={type} id={id} className={buttonClassNames} onClick={onClick} disabled={disabled} ref={ref}>
        {children}
      </button>;
});
ButtonComponent.displayName = "ButtonComponent";
export default ButtonComponent;