import { FC } from "react";
import ReactInputMask from "react-input-mask";
import InputComponent from "../input/input";
import { forwardRef } from "react";
import styles from "./te-input.module.scss";
interface ITelInput {
  id: string;
  className?: string;
  label?: string;
  icon?: string;
  value: string | number;
  onChange: (arg?: any) => void;
  onBlur?: (arg?: any) => void;
  placeholder?: string;
  handlerButton?: () => void;
  button?: string | false;
  inputClassName?: string;
  readOnly?: boolean;
}
const TelInput: FC<ITelInput> = forwardRef((props, ref) => {
  const {
    id,
    className,
    label,
    icon,
    value,
    onChange,
    onBlur,
    placeholder,
    handlerButton,
    button,
    inputClassName,
    readOnly
  } = props;
  return <InputComponent id={id} className={className} label={!!label ? label : ""} icon={icon}>
      <>
        <ReactInputMask id={id} mask="+7 999 999-99-99" placeholder={placeholder} alwaysShowMask={false}
      // @ts-ignore
      ref={ref} value={value} onChange={onChange} type="tel" onBlur={onBlur} autoComplete="off" className={inputClassName} readOnly={readOnly} />
        {button && <button className={styles.button} type="button" onClick={handlerButton}>
            {button}
          </button>}
      </>
    </InputComponent>;
});
TelInput.displayName = "TelInput";
export default TelInput;