import { TModalOverlayComponent } from "@/services/types/types";
import styles from "./modal-overlay.module.scss";
import { FC } from "react";
import { motion } from "framer-motion";
const ModalOverlay: FC<TModalOverlayComponent> = ({
  onClose,
  overlayClass
}) => {
  return <motion.div className={`${styles.modalOverlay} ${styles[overlayClass]}`} initial={{
    opacity: 0
  }} animate={{
    opacity: 0.6
  }} exit={{
    opacity: 0
  }} onClick={onClose} data-sentry-element="unknown" data-sentry-component="ModalOverlay" data-sentry-source-file="modal-overlay.tsx"></motion.div>;
};
export default ModalOverlay;