import { FC, useRef } from "react";
import styles from "./email.module.scss";
import EmailInput from "@/components/email-input/email-input";
import ButtonComponent from "@/components/button/button";
import { useAppDispatch } from "@/services/redux/hooks";
import { register, setAuthStatus } from "@/services/redux/features/userSlice";
export const Email: FC = () => {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const handleClick = (e: any) => {
    e.preventDefault();
    if (!ref.current) return;
    dispatch(register({
      email: ref.current.value
    }));
  };
  const backAuth = () => dispatch(setAuthStatus());
  return <>
      <p className={styles.title}>Привяжите ваш Email.</p>
      <p className={styles.infoText}>
        Заполните адрес электронной почты для регистрации аккаунта.
      </p>
      <form className={styles.form} action="#">
        <EmailInput
      // @ts-ignore: Unreachable code error
      className={styles.input} ref={ref} label="Введите Email" id={"email-reg"} data-sentry-element="EmailInput" data-sentry-source-file="email.tsx" />
        <ButtonComponent
      // @ts-ignore: Unreachable code error
      onClick={handleClick} className={styles.button} data-sentry-element="ButtonComponent" data-sentry-source-file="email.tsx">
          <>Привязать</>
        </ButtonComponent>
        <button className={styles.buttonBack} onClick={backAuth}>
          Вернуться назад
        </button>
      </form>
    </>;
};