import styles from "./enter-qr-social.module.scss";
import { FC } from "react";
import { AppStoreMiniIcon, GooglePlayMiniIcon, RustoreMiniIcon } from "@/components/icon";
import { CustomTooltip } from "@/components/custom-tooltip/custom-tooltip";
interface IEnterQrSocial {}
export const EnterQrSocial: FC<IEnterQrSocial> = () => {
  return <div className={styles.list} data-sentry-component="EnterQrSocial" data-sentry-source-file="enter-qr-social.tsx">
      <CustomTooltip text={"Скачать в AppStore"} data-sentry-element="CustomTooltip" data-sentry-source-file="enter-qr-social.tsx">
        <div className={styles.item}>
          <a href="https://apps.apple.com/app/%D0%BE%D1%85%D0%BE%D1%82%D0%B0%D0%BA%D1%82%D0%B8%D0%B2-%D0%BE%D1%85%D0%BE%D1%82%D0%B0-%D1%80%D1%8B%D0%B1%D0%B0%D0%BB%D0%BA%D0%B0/id6502176237" className={styles.link} target="_blank" rel="noopener nofollow">
            <AppStoreMiniIcon data-sentry-element="AppStoreMiniIcon" data-sentry-source-file="enter-qr-social.tsx" />
          </a>
        </div>
      </CustomTooltip>
      <CustomTooltip text={"Скачать в Google Play"} data-sentry-element="CustomTooltip" data-sentry-source-file="enter-qr-social.tsx">
        <div className={styles.item}>
          <a href="https://play.google.com/store/apps/details?id=comm.ohotaktiv" className={styles.link} target="_blank" rel="noopener nofollow">
            <GooglePlayMiniIcon data-sentry-element="GooglePlayMiniIcon" data-sentry-source-file="enter-qr-social.tsx" />
          </a>
        </div>
      </CustomTooltip>
      <CustomTooltip text={"Скачать в RuStore"} data-sentry-element="CustomTooltip" data-sentry-source-file="enter-qr-social.tsx">
        <div className={styles.item}>
          <a href="https://rustore.ru/catalog/app/com.ohotaktiv" className={styles.link} target="_blank" rel="noopener nofollow">
            <RustoreMiniIcon data-sentry-element="RustoreMiniIcon" data-sentry-source-file="enter-qr-social.tsx" />
          </a>
        </div>
      </CustomTooltip>
    </div>;
};