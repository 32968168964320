import styles from "./auth-popup.module.scss";
import Modal from "../../modal/modal";
import { FC } from "react";
import { IPopup } from "@/services/types/types";
import { Enter } from "./enter/enter";
import { Code } from "./code/code";
import { useAppSelector } from "@/services/redux/hooks";
import { getAuthStatus } from "@/services/redux/features/userSlice";
import { Email } from "./email/email";
export const AuthPopup: FC<IPopup> = props => {
  const {
    isOpen,
    onClose,
    action
  } = props;
  const step = useAppSelector(getAuthStatus).step;
  const isAuth = useAppSelector(getAuthStatus).isAuth;
  return !isAuth && <Modal isOpen={isOpen} onClose={onClose} containerClassname={styles.modal_container} title={step === "enter" ? 'ОхотАктив ID' : false}>
        <div className={styles.wrap}>
          {step === "enter" && <Enter action={action} onClose={onClose} />}
          {step === "code" && <Code />}
          {step === "email" && <Email />}
        </div>
      </Modal>;
};