import styles from "./seller-popup.module.scss";
import Modal from "../../modal/modal";
import { FC, useEffect } from "react";
import { phoneFormat } from "@/utils/functions/phone-format";
export const SellerPopup: FC<any> = ({
  isOpen,
  onClose,
  sellerInfo
}) => {
  useEffect(() => {
    ym(42989679, "reachGoal", "showTelSellerFromComission");
  }, []);
  return <Modal isOpen={isOpen} title={sellerInfo.phone ? phoneFormat(sellerInfo.phone) : "Телефон не указан"} onClose={onClose} data-sentry-element="Modal" data-sentry-component="SellerPopup" data-sentry-source-file="seller-popup.tsx">
      <div className={styles.wrap}>
        <p className={styles.text}>
          Имя: {sellerInfo.name ? sellerInfo.name : "Имя не указано"}
        </p>
        <p className={styles.text}>
          Email: {sellerInfo.email ? sellerInfo.email : "Email не указан"}
        </p>
        <p className={styles.text}>
          Не сообщайте продавцу данные своей банковской карты.
        </p>
      </div>
    </Modal>;
};