import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import styles from "./code-input.module.scss";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { getAuthStatus, isQueryEmail, sendAuthCode } from "@/services/redux/features/userSlice";
import { callMessage } from "@/utils/functions/callMessage";
interface ICodeInput {
  id?: string;
  className?: string;
}
interface IAction {
  e: ChangeEvent<HTMLInputElement>;
  isFirst: boolean;
  state: number | string;
  setState: (x: string | number) => void;
  ref: HTMLInputElement;
}
const CodeInput: FC<ICodeInput> = props => {
  const variant = useAppSelector(getAuthStatus).variant;
  const queryEmail = useAppSelector(isQueryEmail);
  const {
    id,
    className
  } = props;
  const dispatch = useAppDispatch();
  const requestCode = useAppSelector(getAuthStatus).codeRequest;
  const [first, setFirst] = useState<string | ''>('');
  const [second, setSecond] = useState<string | ''>('');
  const [third, setThird] = useState<string | ''>('');
  const [fourth, setFourth] = useState<string | ''>('');
  const firstRef = useRef<HTMLInputElement>(null);
  const secondRef = useRef<HTMLInputElement>(null);
  const thirdRef = useRef<HTMLInputElement>(null);
  const fourthRef = useRef<HTMLInputElement>(null);
  const handleChange = (last: string) => {
    // @ts-ignore: Unreachable code error
    const summaryValues = first + second + third + last;
    if (!Number(summaryValues)) {
      callMessage('Ошибка', 'Что-то пошло не так. Повторите попытку', false);
      return;
    }
    if (summaryValues.length === 4) {
      if (queryEmail) {
        dispatch(sendAuthCode({
          code: summaryValues,
          variant: "tel"
        }));
      } else {
        dispatch(sendAuthCode({
          code: summaryValues,
          variant
        }));
      }
    }
  };
  const findEmptyValue = (target: string) => {
    if (!first && target === 'first') {
      firstRef.current && firstRef.current.focus();
      return true;
    } else if (!second && target === 'second') {
      secondRef.current && secondRef.current.focus();
      return true;
    } else if (!third && target === 'third') {
      thirdRef.current && thirdRef.current.focus();
      return true;
    } else return false;
  };
  useEffect(() => {
    if (firstRef && firstRef.current) {
      firstRef.current.focus();
    }
  }, [firstRef]);
  return <div className={styles.code_wrap} onClick={() => findEmptyValue('first')} data-sentry-component="CodeInput" data-sentry-source-file="code-input.tsx">
      <input type="number" inputMode={'numeric'} className={`${styles.input}`} value={first} onChange={e => {
      if (!first && e.target.value.length) {
        setFirst(e.target.value);
        secondRef.current && secondRef.current.focus();
      } else if (!e.target.value && first) {
        setFirst('');
      }
    }} onFocus={() => setFirst('')} ref={firstRef} />
      <input type="number" inputMode={'numeric'} className={`${styles.input}`} value={second} onChange={e => {
      const empty = findEmptyValue('first');
      if (empty) return;
      if (!second && e.target.value.length) {
        setSecond(e.target.value);
        thirdRef.current && thirdRef.current.focus();
      } else if (!e.target.value && second) {
        setSecond('');
        firstRef.current && firstRef.current.focus();
      }
    }} onFocus={() => setSecond('')} onKeyDown={e => {
      if (e.key === 'Backspace' && !second) {
        firstRef.current && firstRef.current.focus();
      }
    }} ref={secondRef} />
      <input type="number" inputMode={'numeric'} className={`${styles.input}`} value={third} onChange={e => {
      const empty = findEmptyValue('second');
      if (empty) return;
      if (!third && e.target.value.length) {
        setThird(e.target.value);
        fourthRef.current && fourthRef.current.focus();
      } else if (!e.target.value && third) {
        setThird('');
        secondRef.current && secondRef.current.focus();
      }
    }} onFocus={() => setThird('')} onKeyDown={e => {
      if (e.key === 'Backspace' && !third) {
        secondRef.current && secondRef.current.focus();
      }
    }} ref={thirdRef} />
      <input type="number" inputMode={'numeric'} className={`${styles.input}`} value={fourth} onChange={e => {
      const empty = findEmptyValue('third');
      if (empty) return;
      if (!fourth && e.target.value.length) {
        setFourth(e.target.value);
        handleChange(e.target.value);
      } else if (!e.target.value && fourth) {
        setFourth('');
        thirdRef.current && thirdRef.current.focus();
      }
    }} onFocus={() => setFourth('')} onKeyDown={e => {
      if (e.key === 'Backspace' && !fourth) {
        thirdRef.current && thirdRef.current.focus();
      }
    }} ref={fourthRef} />
    </div>;
};
export default CodeInput;