import { TagIcon } from "@/components/icon";
import styles from "./points-item.module.scss";
import { FC } from "react";
import ButtonComponent from "@/components/button/button";
interface IPointsItem {
  point: {
    code: string;
    work_time: string;
    work_hours: any;
    "address-source": string;
    latitude: number;
    longitude: number;
    location: {
      address: string;
      latitude: number;
      longitude: number;
    };
    uuid: string;
  };
  nameCompany: string;
  openBalloon: (coordinates: [number, number], uuid: string) => void;
  handleChoosePoint: (latitude: number, longitude: number) => void;
}
export const PointsItem: FC<IPointsItem> = ({
  point,
  nameCompany,
  openBalloon,
  handleChoosePoint
}) => {
  return <div className={styles.card__point} data-sentry-component="PointsItem" data-sentry-source-file="points-item.tsx">
      <TagIcon className={styles.card__icon} data-sentry-element="TagIcon" data-sentry-source-file="points-item.tsx" />
      <div className={styles.card__point_content}>
        <p className={styles.card__point_choosed}>
          Пункт выдачи {nameCompany}&nbsp;
          {point.location && point.location.address ? point.location.address : point["address-source"]}
          <span>{point.work_time ? point.work_time : "09:00 - 20:00"}</span>
        </p>
        <div className={styles.card__point_actions}>
          <ButtonComponent data-choose-coordinates={[point.location.latitude, point.location.longitude]} className={styles.card__point_choose} onClick={() => handleChoosePoint(point.location.latitude, point.location.longitude)} data-sentry-element="ButtonComponent" data-sentry-source-file="points-item.tsx">
            <>Выбрать</>
          </ButtonComponent>
          <button className={styles.card__point_show} onClick={() => openBalloon([point.location.latitude, point.location.longitude], point.uuid)}>
            Показать на карте
          </button>
        </div>
      </div>
    </div>;
};