import { FC, useRef } from "react";
import styles from "./input-uploader.module.scss";
import Image from "next/image";
import { CloseIcon, AddPhotoIcon } from "../icon";
import { callMessage } from "@/utils/functions/callMessage";
import { deleteImageAnnouncement } from "@/utils/api/postAnnouncement";
interface IInputUploader {
  imagesArray: any;
  setImagesArray: any;
  value?: any;
  onChange?: any;
  disabled?: boolean;
}
export const InputUploader: FC<IInputUploader> = ({
  imagesArray,
  setImagesArray,
  value,
  onChange,
  disabled
}) => {
  const inputRef = useRef(null) as any;
  const handleChange = () => {
    Object.values(inputRef.current.files).forEach((el: any) => {
      const checkDoublicat = imagesArray.find((elem: any) => elem.name === el.name);
      if (el.name === checkDoublicat?.name) {
        return callMessage("Ошибка!", "Вы выбрали одинаковые фотографии.", false);
      }
      setImagesArray((prevArray: any) => [...prevArray, el]);
    });
  };
  const handleRemoveImage = (image: any, index: Number) => {
    if (image.value) {
      deleteImageAnnouncement(image.value).then((res: any) => {
        if (!res.success) return;
        setImagesArray(imagesArray.filter((img: any, i: number) => i !== index));
        const fileListArr = Array.from(inputRef.current.files);
        fileListArr.splice(Number(index), 1);
      });
      return;
    }
    setImagesArray(imagesArray.filter((img: any, i: any) => i !== index));
    const fileListArr = Array.from(inputRef.current.files);
    fileListArr.splice(Number(index), 1);
  };
  return <div className={styles.uploader} data-sentry-component="InputUploader" data-sentry-source-file="input-uploader.tsx">
      <div className={styles.uploaderWrap}>
        <div className={styles.uploaderOutput}>
          {imagesArray.length > 0 && imagesArray.map((image: any, index: Number) => {
          return <div key={`${index + "divImagesArray" + "inputhUpLoader"}`} className={styles.uploaderOutputImageWrap}>
                  <Image className={styles.uploaderOutputImage} src={image.value ? `http://ohotaktiv.ru${image.value}` : URL.createObjectURL(image)} alt="image" width={200} height={200} />
                  <button onClick={() => handleRemoveImage(image, index)} className={styles.uploaderOutputImageRemove} type="button" disabled={disabled}>
                    <CloseIcon />
                  </button>
                </div>;
        })}
        </div>
        <div className={styles.uploaderText}>
          <AddPhotoIcon className={styles.uploaderTextIcon} data-sentry-element="AddPhotoIcon" data-sentry-source-file="input-uploader.tsx" />
          <div className={styles.uploaderTextWrap}>
            <p className={styles.uploaderTextBig}>Выберите фотографии</p>
            <p className={styles.uploaderTextSmall}>
              или перетащите изображение в эту область
            </p>
          </div>
        </div>

        <input type="file" className={styles.uploaderInput} name="files" id="photo-gallery" multiple={true} accept="image/jpeg, image/png, image/jpg" ref={inputRef} onChange={handleChange} disabled={disabled} />
      </div>
      <p className={styles.uploaderHint}>
        Прикрепите фото, не более 6 в формате .png, .jpg не более 16 мб
      </p>
    </div>;
};