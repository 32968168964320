import { TModalCloseComponent } from "@/services/types/types";
import styles from "./modal-close.module.scss";
import { FC } from "react";
import { CloseIcon } from "@/components/icon";
const ModalClose: FC<TModalCloseComponent> = ({
  onClose,
  closeColor
}) => {
  return <button onClick={onClose} className={styles.close} data-sentry-component="ModalClose" data-sentry-source-file="modal-close.tsx">
      <CloseIcon fill={closeColor} className={styles.icon} data-sentry-element="CloseIcon" data-sentry-source-file="modal-close.tsx" />
    </button>;
};
export default ModalClose;