'use client';

import { YMapMarker } from "ymap3-components";
import styles from "./map-marker.module.scss";
import { CloseIcon, TagIcon } from "@/components/icon";
import React, { JSX, ReactElement } from "react";
import { Feature } from "@yandex/ymaps3-types";
interface IMapMarker {
  openPopup?: () => void;
  point: Feature;
  handleChoose?: () => void;
  isOpenPopup?: false | string;
  handleClosePopup?: () => void;
  customPopup?: ReactElement | JSX.Element | any;
}
const CustomMapMarker = (props: IMapMarker) => {
  const {
    openPopup,
    point,
    handleChoose,
    isOpenPopup,
    handleClosePopup,
    customPopup
  } = props;
  const popup = customPopup ? customPopup : <YMapMarker zIndex={1_000} key={point.name + 'popup'} coordinates={point.geometry.coordinates}>
        <div className={styles.custom_balloon} style={{
      zIndex: 1_000
    }}>
          <div className={styles.balloon_wrap}>
            <p className={styles.name}>{point.name}</p>
            <p>
              {point.location && point.location.address ? point.location.address : point["address-source"]}
            </p>
            <p className={styles.time}>Время работы</p>
            <button className={styles.close} onClick={handleClosePopup}>
              <CloseIcon />
            </button>
            <button className={styles.choose} onClick={handleChoose}>
              Выбрать
            </button>
          </div>
        </div>
      </YMapMarker>;
  return <>
      <YMapMarker key={point.name} coordinates={point.geometry.coordinates} zIndex={0} data-sentry-element="YMapMarker" data-sentry-source-file="map-marker.tsx">
        <div className={styles.marker_wrap}>
          <button onClick={openPopup} className={styles.custom_marker}>
            <TagIcon fill={point.color ? point.color : ''} data-sentry-element="TagIcon" data-sentry-source-file="map-marker.tsx" />
          </button>
        </div>
      </YMapMarker>
      {isOpenPopup === point.id && popup}
    </>;
};
export default CustomMapMarker;