import styles from "./categories-popup.module.scss";
import Modal from "../../modal/modal";
import { FC, useEffect, useState } from "react";
import { Category } from "./category/category";
import { useParams, usePathname, useRouter } from "next/navigation";
import ButtonComponent from "@/components/button/button";
import { getAllSubsections } from "@/utils/api/catalog";
import { Preloader } from "@/components/loading/preloader";
interface ICategoriesPopup {
  isOpen: boolean;
  onClose: () => void;
  categories: Array<{
    name: string;
    code: string;
  }>;
}
export const CategoriesPopup: FC<ICategoriesPopup> = ({
  isOpen,
  onClose,
  categories
}) => {
  const pathname = usePathname();
  const param = useParams();
  const router = useRouter();
  const [choosed, setChoosed] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [subCategories, setSubCategories] = useState([]);
  const folder = pathname.includes("catalog") || pathname.includes("search") ? "/catalog/" : pathname;
  const handleChooseCategory = () => {
    router.push(`${folder}${choosed}`);
    onClose();
  };
  useEffect(() => {
    if (pathname.includes("/catalog/")) {
      getAllSubsections(param.section).then(res => {
        setIsLoading(false);
        setSubCategories(res.items.subdirectories);
      });
    } else {
      setIsLoading(false);
    }
  }, []);
  return <Modal isOpen={isOpen} title={"Категории"} onClose={onClose} data-sentry-element="Modal" data-sentry-component="CategoriesPopup" data-sentry-source-file="categories-popup.tsx">
      {isLoading ? <div className={styles.wrap}>
          <Preloader className={styles.preloader} />
        </div> : <>
          <div className={styles.wrap}>
            <div className={styles.categoriesList}>
              {categories.map((category: {
            name: string;
            code: string;
          }, index: number) => {
            return <Category key={index} category={category} setChoosed={setChoosed} subCategories={subCategories} />;
          })}
            </div>
          </div>
          <ButtonComponent className={styles.button} onClick={handleChooseCategory} disabled={!!!choosed}>
            <>Посмотреть</>
          </ButtonComponent>
        </>}
    </Modal>;
};