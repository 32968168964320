import styles from "./delivery-popup.module.scss";
import Modal from "../../modal/modal";
import { FC } from "react";
import { IPopup } from "@/services/types/types";
export const DeliveryPopup: FC<IPopup> = ({
  isOpen,
  onClose
}) => {
  return <Modal isOpen={isOpen} title={"Доставка ОхотАктив"} onClose={onClose} data-sentry-element="Modal" data-sentry-component="DeliveryPopup" data-sentry-source-file="delivery-popup.tsx">
      <div className={styles.wrap}>
        <p className={styles.text}>
          Доставка осуществляется собственной логистической компанией с
          удаленного склада до магазинов розничной сети «ОхотАктив». Срок
          доставки зависит от удаленности магазинов.
        </p>
        <p className={styles.smallText}>
          * Не распространяется на услугу «Перемещение»
        </p>
      </div>
    </Modal>;
};