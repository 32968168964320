"use client";

import styles from "./geo-popup.module.scss";
import Modal from "../../modal/modal";
import InputComponent from "../../input/input";
import { CityIcon, SearchIcon } from "../../icon/index";
import { FC, useEffect, useRef, useState } from "react";
import { IGeoCity, IPopup } from "@/services/types/types";
import { setCity } from "@/services/redux/features/citySlice";
import { useAppDispatch } from "@/services/redux/hooks";
import { clearItemsInfo, setStep } from "@/services/redux/features/orderSlice";
import { getShops } from "@/utils/api/shops";
import { createServerCookie } from "@/app/actions";
import { setCookie } from "@/utils/functions/cookie";
import defaultCities from "./defaultCities.json";
import useDebouncedFunction from "@/services/hooks/useDebouncedFunction";
import { getGeo } from "@/utils/api/dadata";
import { translit } from "@/utils/functions/translit";
import GeoPopupSkeleton from "@/components/popups/geo-popup/skeleton";
import { useRouter } from "next/navigation";
import { setDefaultCity } from "@/utils/functions/setDefaultCity";
interface IInputEvent {
  target: {
    value: string;
  };
}
type geoEl = {
  data: {
    city: string;
  };
};
export const GeoPopup: FC<IPopup> = props => {
  const {
    isOpen,
    onClose
  } = props;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [allCities, setAllCities] = useState<IGeoCity[]>([]);
  const [filteredCities, setFilteredCities] = useState<IGeoCity[]>(defaultCities);
  const inputRef = useRef<HTMLInputElement>(null);
  const [citySearch, setCitySearch] = useState("");
  const loadShops = async () => {
    await getShops().then(res => {
      setAllCities(res.items);
      setIsLoading(false);
    });
  };
  const handleClick = async (city: IGeoCity) => {
    const findShop = allCities.find(shop => shop.name === city.name);
    dispatch(setCity({
      name: city.name,
      code: city.translit_title
    }));
    ym(42989679, "reachGoal", "changeCity");
    dispatch(clearItemsInfo());
    dispatch(setStep(1));
    try {
      if (findShop) {
        const cookie = {
          name: city.name,
          code: city.translit_title,
          lat: findShop.lat,
          lon: findShop.lon
        };
        createServerCookie("choosedCity", JSON.stringify(cookie)).then(r => {
          setCookie("choosedCity", JSON.stringify(cookie));
          onClose();
        });
      } else {
        setDefaultCity(city.name);
        onClose();
      }
    } catch (err) {
      setDefaultCity(city.name);
    } finally {
      document.body.style.overflow = "auto";
    }
    // document.location.reload();
  };
  const debounce = useDebouncedFunction(city => {
    if (city.length === 0) {
      setFilteredCities(defaultCities);
    } else {
      getGeo(city).then(res => {
        const cities = res.suggestions.map((el: geoEl) => ({
          name: el.data.city,
          translit_title: translit(el.data.city)
        }));
        setCitySearch(city);
        setFilteredCities(cities);
      });
    }
  }, 500);
  const handleInputChange = (event: IInputEvent) => {
    const value = event.target.value;
    debounce(value);
  };
  useEffect(() => {
    loadShops();
    inputRef.current?.focus();
  }, []);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);
  const closeModal = () => {
    onClose();
    document.body.style.overflow = "auto";
  };
  return <Modal isOpen={isOpen} title={"Выберите свой город"} onClose={closeModal} containerClassname={styles.modal_container} data-sentry-element="Modal" data-sentry-component="GeoPopup" data-sentry-source-file="geo-popup.tsx">
      <div className={styles.wrap}>
        <p className={styles.text}>
          Это нужно для того чтобы заранее увидеть актуальные цены, способы
          получения и наличие товаров.
        </p>
        {isLoading ? <GeoPopupSkeleton /> : <>
            <InputComponent id={"geo-popup-city"} label={"Введите город"} className={styles.inputWrap} icon={<SearchIcon className={styles.icon} />}>
              <input id={"geo-popup-city"} type="text" maxLength={50} className={styles.input} placeholder=" " onChange={handleInputChange} ref={inputRef} />
            </InputComponent>
            <div className={styles.info}>
              <CityIcon />
              <p className={styles.infoText}>
                <span>–</span> города, в которых есть магазин «ОхотАктив»
              </p>
            </div>
            <div className={`${styles.defaultCitiesList} ${citySearch.length && styles.defaultCitiesList_scroll}`}>
              {filteredCities.map((el, index) => {
            if (el.name.includes("км")) return;
            const findShop = allCities.find(shop => shop.name === el.name);
            return <button key={`${index + "buttonkeySearchList" + "geopopup"}`} className={styles.defaultCitiesButton} onClick={() => handleClick(el)}>
                    {findShop ? <CityIcon /> : <span className={styles.empty}></span>}
                    {el.name}
                    {/* {citySearch && (
                     <span className={styles.region}>
                     {" "}
                     {el.data.region_with_type}
                     </span>
                     )} */}
                  </button>;
          })}
            </div>
          </>}
      </div>
    </Modal>;
};