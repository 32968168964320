import styles from "./available-popup.module.scss";
import Modal from "../../modal/modal";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { IPopup, IShop, IStories } from "@/services/types/types";
import { getShops } from "@/utils/api/shops";
import { AvailablePopupStore } from "./available-popup-store/available-popup-store";
import { SkeletonLoader } from "@/components/skeleton-loader/skeleton-loader";
import { TagIcon } from "@/components/icon";
import { YMap, YMapComponentsProvider, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker, YMapCollection, YMapListener } from "ymap3-components";
import { defaultLocation, YMAP_API_KEY } from "@/services/constants/constants";
import { AccordionShop } from "@/components/accordion-shop/accordion-shop";
import { UseMediaQuery } from "@/utils/functions/useMediaQuery";
import Tabs from "@/components/popups/available-popup/Tabs";
import { LngLat } from "@yandex/ymaps3-types";
const avalibleShops = (store: any[], shops: any[]) => {
  const filtredShops: IShop[] = [];
  const filtredCity: any[] = [];
  shops.forEach(element => {
    const validItems = element.items.filter((elem: IShop) => {
      const storeItem = store.find(storeItem => {
        if (elem.value === storeItem.code) {
          return storeItem;
        }
      });
      if (storeItem) {
        elem.quantity = storeItem.amount;
        const newPoint = {
          ...elem,
          quantity: storeItem.amount,
          geometry: {
            coordinates: [Number(elem.gps_s), Number(elem.gps_n)],
            type: "Point"
          }
        };
        filtredShops.push(newPoint);
        return true;
      }
      return false;
    });
    if (validItems.length > 0) {
      filtredCity.push({
        ...element,
        items: validItems
      });
    }
  });
  return {
    filtredShops,
    filtredCity
  };
};
export const ZOOM_RANGE = {
  min: 3.5,
  max: 21
};
export const AvailablePopup: FC<IPopup> = props => {
  const {
    isOpen,
    onClose,
    store,
    warehouse
  } = props;
  const [shopList, setShopList] = useState<IShop[]>([]);
  const [location, setLocation] = useState<typeof defaultLocation>(defaultLocation);
  const [cityShops, setCityShops] = useState<IStories[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadedMap, setIsLoadedMap] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<'list' | 'map'>('list');
  const isMobile = UseMediaQuery('(max-width: 768px)');
  const ymapRef = useRef(null);
  useEffect(() => {
    if (!store) {
      setLoading(true);
      return;
    }
    getShops().then(res => {
      if (res.success) {
        const {
          filtredShops,
          filtredCity
        } = avalibleShops(store, res.items);
        setCityShops(filtredCity);
        setShopList(filtredShops);
        setLoading(false);
      }
    });
  }, []);
  const Marker = useCallback((feature: {
    feature: IShop;
  }) => {
    const point = feature.feature;
    return <YMapMarker key={point.address} coordinates={point.geometry.coordinates as LngLat} zIndex={0}>
        <div className={styles.marker_wrap}>
          <button className={styles.custom_marker}>
            <TagIcon fill={"red"} />
          </button>
        </div>
      </YMapMarker>;
  }, []);
  const handleSetCentreCity = (cords: number[]) => {
    setLocation({
      center: cords,
      zoom: 12
    });
  };
  const handleSetCentreShop = (cords: number[]) => {
    setLocation({
      ...location,
      center: cords,
      zoom: 16
    });
    if (isMobile) setActiveTab('map');
  };
  return <Modal isOpen={isOpen} title={""} onClose={onClose} containerClassname={styles.modal_container} className={styles.modal} data-sentry-element="Modal" data-sentry-component="AvailablePopup" data-sentry-source-file="available-popup.tsx">
      {loading ? <div className={styles.wrap}>
          <SkeletonLoader containerTag="div" height={isMobile ? '100px' : 576} width={isMobile ? `100%` : '30%'} mb={4} />
          <SkeletonLoader containerTag="div" height={isMobile ? '70vh' : 576} width={isMobile ? `100%` : '70%'} mb={4} />
        </div> : <div className={styles.wrap}>
          {isMobile ? <>
              <p className={styles.title}>Товар есть в этих магазинах:</p>
              <Tabs listHandler={() => setActiveTab('list')} mapHandler={() => setActiveTab('map')} activeTab={activeTab} />
            </> : null}
          <ul className={`${styles.list} ${activeTab === 'list' ? styles.visible : ''}`}>
            {!isMobile ? <p className={styles.title}>Товар есть в этих магазинах:</p> : null}
            <AccordionShop stores={cityShops} isSearch={false} style={styles.accordion} wareHouse={warehouse! > 0} setCenter={handleSetCentreCity} openBalloon={handleSetCentreShop} isPreview={true} showPhone={false} />
          </ul>
          <div className={`${styles.map} ${activeTab === 'map' ? styles.visible : ''}`}>
            <YMapComponentsProvider apiKey={YMAP_API_KEY} lang="ru_RU" onLoad={() => setIsLoadedMap(true)}>
              {isLoadedMap && <YMap key="map" ref={ymapRef} location={location} copyrights={true} mode="vector" theme="light" zoomRange={ZOOM_RANGE}>
                  <YMapDefaultSchemeLayer />
                  <YMapDefaultFeaturesLayer />
                  {!!shopList.length ? <YMapCollection>
                      {shopList.map((point: any, index: number) => <Marker key={index} feature={point} />)}
                    </YMapCollection> : null}
                </YMap>}
            </YMapComponentsProvider>
          </div>
        </div>}
    </Modal>;
};